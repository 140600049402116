import styled, { css } from 'styled-components/macro';
import GatsbyLink from 'gatsby-link';
import { Button } from '@material-ui/core';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';
import PlayIcon from '@material-ui/icons/PlayCircleOutline';

export const MainDiv = styled.div`
  display: flex;
  width: 80%;
  margin: auto;
  @media (max-width: 838px) {
    width: 100%;
  }
  @media (max-width: 325px) {
    flex-direction: column;
  }
`;
const SharedProfileCSS = css`
  width: 200px;
  text-align: center;
  margin: auto;
  cursor: pointer;
  span {
    font-weight: bold;
    font-size: ${fontSize.xLarge};
    line-height: 22px;
    font-family: 'Open Sans', sans-serif !important;
  }
  div {
    font-weight: 400;
    font-size: ${fontSize.medium};
    line-height: 22px;
    font-family: 'Open Sans', sans-serif !important;
  }
  @media (max-width: 740px) {
    align-items: center;
    // display: flex;
  }
`;
export const ButtonProfileContainer = styled(Button)`
  &.button {
    ${SharedProfileCSS};
    text-transform: capitalize;
    display: inherit;
    width: fit-content;
    font-family: 'Open Sans', sans-serif !important;
  }
`;
export const ProfileContainer = styled.div`
  ${SharedProfileCSS};
`;
export const ProfileBubble = styled.div`
  width: 140px;
  height: 140px;
  background: ${colors.white};
  border: ${props => (props.active ? '5px solid #00B4AD' : '5px solid white')};
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  padding: 9px;
  margin: auto;
  margin-bottom: 8px;
  @media (max-width: 740px) {
    width: 100px;
    height: 100px;
    padding: 5px;
  }
`;
export const ProfileImg = styled.img`
  width: 110px;
  height: 112px;
  border-radius: 50%;
  @media (max-width: 740px) {
    width: 80px;
    height: 80px;
  }
`;
export const ProfileInfoContainer = styled.div`
  flex: 2;
  max-width: 397px;
  min-height: 515px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 740px) {
    margin: auto !important;
    width: 90%;
  }
`;
export const BlueContainer = styled.div`
  background: #f1f8f7;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  height: ${({ isFixHeight }) => isFixHeight && '70px'};
`;
export const Features = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  .heading {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 7px;
    margin-top: 20px;
    &:first-child {
      margin-top: 0px;
    }
  }
  .container {
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
  }
  div {
    align-items: center;
    display: flex;
    width: 120px;
  }
  .text {
    font-weight: 400;
    font-size: ${fontSize.medium};
    margin-left: 8px;
    text-align: left;
    font-family: 'Open Sans', sans-serif !important;
    text-transform: capitalize;
    max-width: 80px;
    // width: inherit;
    line-height: 16px;
  }
  img {
    width: 50%;
  }
`;
export const ComputerDiv = styled.div`
  display: block;
  @media (max-width: 740px) {
    display: none;
  }
`;
export const MobileDiv = styled.div`
  display: none;
  @media (max-width: 740px) {
    display: block;
  }
`;
export const Link = styled(GatsbyLink)`
  padding: 1rem;
  font-size: ${fontSize.large};
  font-family: 'Open Sans', sans-serif !important;
  color: ${colors.primary};
  align-items: center;
  display: flex;
  text-transform: uppercase;
  width: fit-content;
  margin: auto;
  font-weight: bold;
  text-decoration: none;
  margin-bottom: 0;
  span {
    margin-right: 1rem;
  }
`;
export const StyledButton = styled(Button)`
  // width: 120px;
  // font-family: Open Sans !important;
  display: flex;
  align-items: center;
`;
export const StyledPlayIcon = styled(PlayIcon)`
  font-size: 4rem !important;
  color: ${colors.white};
  position: absolute;
  margin-left: 80px;
  cursor: pointer;
`;
export const NumberOfJob = styled.p`
  font-weight: 700;
  font-size: ${fontSize.xxLarge};
  margin: 0;
`;
export const JobsCompletedDescription = styled.p`
  font-size: ${fontSize.regular};
  margin: 0;
`;
export const JobCompletedContainer = styled.div`
  &&& {
    background: ${colors.lightGrey};
    padding: 12px 0px;
    border-radius: 6px;
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
`;
