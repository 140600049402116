import React from 'react';

// HowItWorksData
import Image1 from './assets/1.png';
import Image2 from './assets/2.png';
import Image3 from './assets/3.png';
import Image4 from './assets/4.png';

export const HowItWorksData = [
  {
    title: 'Tell us what you need help with',
    image: Image1,
    points: [
      <>
        {' '}
        Describe your job scope with clear instructions, job benefits and your
        required part timer profile.
        <br /> <br />
      </>
    ]
  },
  {
    title: 'Tell us when you need the service for',
    image: Image2,
    points: [
      <>
        {' '}
        Post up single or multiple shifts. Select days and number of GoGetters.{' '}
        <br />
        <br />
      </>
    ]
  },
  {
    title: 'Post the job',
    image: Image3,
    points: [<> Review job details and proceed. </>]
  },
  {
    title: 'Connect with GoGetters',
    image: Image4,
    points: [
      <>
        {' '}
        Engage with GoGetter via GoGet chat to confirm job. <br />
        <br />
      </>
    ]
  }
];
