import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import { HowItWorksData } from './const/data';
import HowItWorks from '../shared/HowItWorks/index';

const MainDiv = styled.div`
  margin-top: -50px;
`;

function HowItWorksStatic() {
  return (
    <MainDiv>
      <TitleHeaderText text="How it works" />
      <HowItWorks className="home business" data={HowItWorksData} />
    </MainDiv>
  );
}

export default HowItWorksStatic;
