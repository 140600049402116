import React from 'react';
import ActivityCard from '../ActivityCard';
import { Column, Image, Text, SubText } from './styles';

function AdvantagesOfGoget({ data, hideBoxShadow }) {
  return (
    <div style={{ padding: '10px' }}>
      <ActivityCard
        hideBoxShadow
        customStyle={{
          marginTop: '-30px',
          padding: '28px',
          boxShadow: hideBoxShadow && 'none',
          alignItems: 'flex-start'
        }}
      >
        {data.map((obj, index) => {
          return (
            <Column key={index}>
              <Image
                src={obj?.uspIcon?.fluid?.src || obj.img}
                alt={obj?.uspTitle || obj.header}
              />
              <Text> {obj?.uspTitle || obj.header} </Text>
              <SubText> {obj?.uspDescription || obj.desc} </SubText>
            </Column>
          );
        })}
      </ActivityCard>
    </div>
  );
}

export default AdvantagesOfGoget;
