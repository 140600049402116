import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';
import Slider from '../../views/LandingPage/Slider';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    display: ${({ isSingleImage }) => isSingleImage && 'none'};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: ${({ isSingleImage }) => isSingleImage && 'none'};
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-page-dots {
    display: ${({ isSingleImage }) => isSingleImage && 'none'};
    bottom: 12px;
    @media only screen and (max-width: 630px) {
      bottom: -25px;
    }
  }
  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;
const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const ImageWrapper = styled.div`
  width: 739px;
  height: 400px;
  margin: 0px 160px 20px 0px;
  @media only screen and (max-width: 760px) {
    width: 22rem;
    height: 452px;
  }
  @media only screen and (max-width: 360px) {
    width: 20rem;
    margin: 0px 60px 20px 0px;
  }
`;
const StateWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-around;

  @media only screen and (max-width: 630px) {
    flex-direction: column;
    align-items: center;
  }
`;
const StatPaper = styled.div`
  width: 260px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  @media only screen and (max-width: 630px) {
    margin-top: 55px;
  }
`;
const StatNumber = styled.p`
  font-size: 52px;
  color: ${colors.secondary};
  font-weight: 700;
  margin: 0;
`;
const StatDescription = styled.p`
  font-size: ${fontSize.large};
  color: ${colors.secondary};
  text-align: center;
  width: 200px;
`;

const ImageScroller = props => {
  const [isMobile, setMobile] = useState(false);

  const { jobType, stats, desktopImages, mobileImages } = props;

  useEffect(() => {
    sizeHandler();
    window.addEventListener('resize', sizeHandler);
  }, []);

  const sizeHandler = () => {
    if (window.innerWidth <= 760) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const options = {
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: true,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: true
  };

  const stateDatas = [
    {
      id: 1,
      number: stats?.jobCompleted,
      description: `${jobType} Jobs Completed`
    },
    {
      id: 2,
      number: stats?.noOfGoGetters,
      description: `${jobType} GoGetters`
    }
  ];

  const images = isMobile ? mobileImages : desktopImages;

  return (
    <MainDiv isSingleImage={images?.length === 1}>
      <TitleHeaderText
        styledDesktop={{ marginBottom: '0px' }}
        styledMobile={{
          marginLeft: '1rem',
          marginRight: '1rem',
          marginBottom: '1rem'
        }}
        text={`${jobType}s at work`}
      />
      <Slider options={options}>
        {images?.map((data, index) => {
          return (
            <ImageWrapper key={index}>
              <Image
                src={data?.fluid?.src}
                alt={`${jobType}s at work, goget, goget banner`}
              />
            </ImageWrapper>
          );
        })}
      </Slider>
      <StateWrapper>
        {stateDatas?.map((stat, index) => (
          <StatPaper key={index}>
            <StatNumber>{stat.number}</StatNumber>
            <StatDescription>{stat.description}</StatDescription>
          </StatPaper>
        ))}
      </StateWrapper>
    </MainDiv>
  );
};

export default ImageScroller;
