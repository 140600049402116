import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import Members from '../shared/Members';

const Container = styled.div`
  background-size: 100% 100%;
  background-position: 35px -100px;
  padding-top: 3rem;
  @media (max-width: 760px) {
    background-image: none;
  }
`;
const Description = styled.p`
  margin: 0;
  text-align: center;
  margin-bottom: ${({ extraMargin }) => extraMargin && '70px'};
`;
const Wrapper = styled.div`
  width: 100%;
  @media (max-width: 760px) {
    width: 275px;
    margin: 0 auto;
  }
`;
const Br = styled.br`
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

function MeetOurCommunity({ data, FAQBackground }) {
  return (
    <Container>
      <TitleHeaderText text="Who is a GoGetter?" />
      <Wrapper>
        <Description>
          GoGetters are flexible part timers with various job experiences.
        </Description>
        <Br />
        <Description extraMargin>
          Train and Build your own team of on-demand part timers based on your
          SOPs when needed.
        </Description>
      </Wrapper>
      <br />
      <Members FAQBackground={FAQBackground} data={data} />
    </Container>
  );
}

export default MeetOurCommunity;
