import React from 'react';
import styled from 'styled-components/macro';

import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

import TitleHeaderText from '../../components/TitleHeaderText';

const WrapperBox = styled.div``;
const Container = styled.div`
  display: flex;
  align-items center;
  justify-content: center;
  @media (max-width: 1010px) {
    flex-direction: column;
  }
`;
const ImageWrapper = styled.div`
  width: 335px;
  height: 323px;
  @media (max-width: 1010px) {
    width: 324px;
    height: unset;
    z-index: 1;
  }
`;
const Image = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
`;
const Paper = styled.div`
  max-width: 465px;
  background: ${colors.white};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border: 3px solid #5e35b1;
  border-radius: 20px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  left: -40px;
  @media (max-width: 1010px) {
    left: unset;
    justify-content: space-between;
    position: relative;
    min-width: 314px;
  }
`;
const Header = styled.div`
  background: #5e35b1;
  color: ${colors.white};
  height: 46px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  text-align: center;
  line-height: 3px;
`;
const PaperBody = styled.h3`
  font-size: ${fontSize.oneHalfRem};
  font-weight: 700;
  text-align: center;
  padding: 14px 28px;
  @media (max-width: 600px) {
    padding: 6px 28px;
  }
`;

const ImageAndBox = ({ data, jobType }) => {
  const response = data && data[0];
  return (
    <WrapperBox>
      <TitleHeaderText text="Pay-Per-Use" />
      <Container>
        <ImageWrapper>
          <Image
            src={response?.serviceImage?.fluid?.src}
            alt={`Pay-Per-Use ${jobType} service`}
          />
        </ImageWrapper>
        <Paper>
          <Header>
            <h5>Suggested fee / Hour</h5>
          </Header>
          <PaperBody>{response?.serviceFee}</PaperBody>
        </Paper>
      </Container>
    </WrapperBox>
  );
};

export default ImageAndBox;
