import React, { useState, useEffect } from 'react';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Slide from '@material-ui/core/Slide';
import Slider from '../../LandingPage/Slider';

import {
  Header,
  MainDiv,
  Circle,
  StyledExpansionPanel,
  Points,
  Container,
  ComputerColumn,
  MobileColumn
} from './styles';

function HowItWorks({ data, className, title }) {
  const [expand, setExpand] = useState(0);

  const [isMobile, setMobile] = useState(false);

  const updateWindowDimensions = () => {
    if (window.innerWidth <= 980 && !isMobile) {
      setMobile(true);
    } else if (window.innerWidth > 980 && isMobile) {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);
    return updateWindowDimensions();
  });

  const expandHandler = index => {
    if (expand === index) {
      setExpand(0);
    } else {
      setExpand(index);
    }
  };

  const options = {
    // autoPlay: 4000,
    // pauseAutoPlayOnHover: true,
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: false,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: false
  };

  const createExpandablePanels = isMobile => {
    const renderBox = (obj, index) => (
      <StyledExpansionPanel
        key={index}
        expanded={isMobile || expand === index}
        onClick={() => expandHandler(index)}
      >
        <AccordionSummary>
          <Circle expanded={isMobile || expand === index}> {index + 1} </Circle>
          {obj.title}
        </AccordionSummary>
        <AccordionDetails>
          <Slide
            in={isMobile || expand === index}
            direction={'down'}
            timeout={{ enter: 300 }}
          >
            <div style={{ marginTop: '-18px', zIndex: '2' }}>
              {obj.points.map((point, index) => (
                <Points key={index}> {point} </Points>
              ))}
            </div>
          </Slide>
        </AccordionDetails>
      </StyledExpansionPanel>
    );
    return data.map((obj, index) => {
      return (
        <div key={index} style={{ marginBottom: !isMobile && '1rem' }}>
          {isMobile ? (
            <div key={index}>
              <Container className={className}>
                <img className={className} src={obj.image} alt="banner" />
              </Container>
              {renderBox(obj, index)}
            </div>
          ) : (
            renderBox(obj, index)
          )}
        </div>
      );
    });
  };

  return (
    <>
      <MainDiv className={className} style={{ marginTop: title && '50px' }}>
        <MobileColumn>
          <Slider options={options}>{createExpandablePanels(true)}</Slider>
        </MobileColumn>
        <ComputerColumn className={className}>
          <div style={{ margin: 'auto' }}>
            {title && <Header> {title} </Header>}
            {createExpandablePanels()}
          </div>
        </ComputerColumn>
        {!isMobile && (
          <Container className={className}>
            <img className={className} src={data[expand].image} alt="banner" />
          </Container>
        )}
      </MainDiv>
    </>
  );
}

export default HowItWorks;
