import React, { useState, useEffect } from 'react';
import Stats from '../../../components/Stats';
import Stars from '../../../components/Stars';
import { take } from 'lodash';
import ChevronRightIcon from '@material-ui/icons/ArrowRightAlt';
import Video from '../../../components/Video';

import {
  MainDiv,
  ProfileContainer,
  ProfileBubble,
  ProfileImg,
  ProfileInfoContainer,
  BlueContainer,
  Features,
  ComputerDiv,
  MobileDiv,
  ButtonProfileContainer,
  Link,
  StyledButton,
  StyledPlayIcon,
  NumberOfJob,
  JobsCompletedDescription,
  JobCompletedContainer
} from './styles';

function Members({ data, FAQBackground }) {
  const [activeProfile, setActiveProfile] = useState(0);
  const selectedProfile = data[activeProfile];
  const [showVideo, setShowVideo] = useState(false);
  const [mobile, setMobile] = useState(false);

  const jobCompleted =
    selectedProfile?.jobCompleted && JSON.parse(selectedProfile?.jobCompleted);

  useEffect(() => {
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 740) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const createProfileBubble = (t, profiles) => {
    return (
      <div style={{ flex: 1 }}>
        {profiles.map((member, index) => (
          <div key={index}>
            <ButtonProfileContainer
              className="button"
              onClick={() => setActiveProfile(index + t)}
            >
              <ProfileBubble active={activeProfile === index + t}>
                <ProfileImg
                  src={member?.photo?.fluid?.src || member?.photo}
                  alt={member?.name || 'GoGetters-profile'}
                />
              </ProfileBubble>
              <div>
                <span> {member?.name} </span>
                <br />
                <div> {member?.job || `GoGetter since ${member?.since}`} </div>
              </div>
            </ButtonProfileContainer>
            <br />
            <br />
          </div>
        ))}
      </div>
    );
  };

  const createProfileCard = () => {
    return (
      <ProfileInfoContainer>
        <div>
          {selectedProfile?.banner ? (
            <BlueContainer
              style={{
                padding: '0px',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <StyledPlayIcon onClick={() => setShowVideo(true)} />
              {selectedProfile.video && (
                <>
                  <img
                    alt="banner_photo"
                    src={selectedProfile?.banner}
                    style={{ width: '100%', height: 'inherit' }}
                  />
                  <Video
                    videoSrcURL={selectedProfile.video}
                    videoTitle=""
                    open={showVideo}
                    onClose={() => setShowVideo(false)}
                  />
                </>
              )}
            </BlueContainer>
          ) : (
            <BlueContainer isFixHeight={!!jobCompleted?.number}>
              <ProfileImg
                alt={selectedProfile?.name || 'profile_photo'}
                src={
                  selectedProfile?.photo?.fluid?.src || selectedProfile?.photo
                }
                style={{ position: 'relative', top: '-40px' }}
              />
              <ProfileContainer style={{ margin: 'unset' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'left',
                    paddingLeft: '30px'
                  }}
                >
                  <span> {selectedProfile?.name} </span>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Stars hideRate />
                    <div style={{ fontSize: '11px', color: '#FFA000' }}>
                      {' '}
                      {selectedProfile?.rating}{' '}
                    </div>
                  </div>
                  <Stats className="black" stats={selectedProfile?.stats} />
                </div>
              </ProfileContainer>
            </BlueContainer>
          )}
        </div>
        <Features>
          {selectedProfile?.badges?.length > 1 && (
            <>
              <span className="heading"> Badges </span>
              <span
                className="container"
                style={{ background: '#F8F8F8', padding: '10px 0px' }}
              >
                {selectedProfile.badges.map((badge, index) => (
                  <StyledButton
                    key={index}
                    style={{
                      backgroundColor: 'transparent',
                      pointerEvents: 'none'
                    }}
                  >
                    <img
                      alt="badge"
                      src={badge.image}
                      style={{ width: '45px', height: '45px' }}
                    />
                    <span className="text"> {badge.name} </span>
                  </StyledButton>
                ))}
              </span>
            </>
          )}
          {jobCompleted?.number && (
            <JobCompletedContainer>
              <NumberOfJob>{jobCompleted?.number}</NumberOfJob>
              <JobsCompletedDescription>{`${jobCompleted?.jobType} jobs completed`}</JobsCompletedDescription>
            </JobCompletedContainer>
          )}
          <span className="heading"> Experiences </span>
          <span className="container">
            {selectedProfile?.experience?.map((experience, index) => (
              <a
                href={experience.link}
                key={index}
                style={{ textDecoration: 'none', width: '50%' }}
              >
                <StyledButton>
                  {experience?.image?.fluid?.src ? (
                    <img
                      alt="experience_photo"
                      src={experience?.image?.fluid?.src || experience.image}
                      style={{ width: '45px', height: '45px' }}
                    />
                  ) : (
                    <span style={{ width: '65px', height: '65px' }}>
                      {experience.image}
                    </span>
                  )}
                  <span className="text"> {experience.name} </span>
                </StyledButton>
              </a>
            ))}
          </span>
          {selectedProfile?.ggPage && (
            <>
              <span className="heading"> Brands they’ve worked with </span>
              <span className="container">
                {selectedProfile.brands &&
                  selectedProfile.brands.map((brand, index) => (
                    <StyledButton disabled key={index}>
                      <img alt="brand" src={brand} style={{ width: '70px' }} />
                    </StyledButton>
                  ))}
              </span>
            </>
          )}
        </Features>
        <Link to="/community" style={{ fontFamily: '"Open Sans",sans-serif' }}>
          <span>Learn More</span>
          <ChevronRightIcon />
        </Link>
      </ProfileInfoContainer>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${FAQBackground})`,
        backgroundSize: 'cover'
      }}
    >
      <ComputerDiv>
        <MainDiv>
          {createProfileBubble(0, take(data, 2))}
          {!mobile && createProfileCard()}
          {createProfileBubble(2, [data[2], data[3]])}
        </MainDiv>
      </ComputerDiv>
      <MobileDiv>
        <MainDiv>
          {createProfileBubble(0, take(data, 2))}
          {createProfileBubble(2, [data[2], data[3]])}
        </MainDiv>
        <br />
        <br />
        {mobile && createProfileCard()}
      </MobileDiv>
    </div>
  );
}

export default Members;
