import React from 'react';
import styled from 'styled-components/macro';
import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const StatsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px !important;
  text-align: center;
  margin-left: -5px;

  .number {
    font-size: ${fontSize.xxLarge};
    color: ${colors.secondary};
    font-weight: bold;
    &.black {
      color: ${colors.black};
    }
  }
  .text {
    font-size: ${fontSize.medium};
    font-weight: 400;
    width: 248px;
    flex: 1;
  }
`;

const Content = styled.div`
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

function Stats({ stats, className }) {
  return (
    <StatsDiv>
      {stats?.map((obj, index) => (
        <Content key={index}>
          <span className={`${className}  number`}> {obj.head} </span>
          <br />
          <span className="text"> {obj.text} </span>
        </Content>
      ))}
    </StatsDiv>
  );
}

export default Stats;
