import styled from 'styled-components/macro';
import Accordion from '@material-ui/core/Accordion';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

export const Header = styled.h1`
  text-align: left;
  margin-bottom: 50px;
  font-size: ${fontSize.twoRem};
`;
export const MainDiv = styled.div`
  // width: 80%;
  margin: auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  &.home {
    justify-content: center;
  }
  @media (max-width: 978px) {
    flex-direction: column-reverse;
  }
`;
export const Circle = styled.div`
  background: ${props => (props.expanded ? colors.primary : colors.black)};
  color: white;
  width: 40px;
  height: 35px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  padding-top: 4px;
  margin-right: 12px;
  @media (max-width: 700px) {
    font-size: ${fontSize.xLarge};
    width: 34px;
    height: 30px;
    line-height: 25px;
  }
`;
export const StyledExpansionPanel = styled(Accordion)`
  width: 434px;
  @media (max-width: 330px) {
    width: 19rem;
    margin-left: 8px !important;
  }
  margin-bottom: 1rem;
  padding: 0px;
  border-radius: 1rem !important;
  font-size: ${fontSize.xxLarge};
  font-weight: 700;
  text-align: left;
  border: ${props => props.expanded && `2px solid ${colors.primary}`};
  &.MuiExpansionPanel-root:before {
    height: 0;
  }
  .MuiExpansionPanelSummary-content {
    align-items: center;
    color: ${props => props.expanded && colors.primary};
    @media (max-width: 700px) {
      font-size: ${fontSize.xLarge};
    }
  }
  .MuiExpansionPanelSummary-content.Mui-expanded {
    margin: 0;
  }
  @media (max-width: 978px) {
    margin-right: 1rem !important;
    margin-top: 20px !important;
    max-width: 350px !important;
  }

  a {
    color: ${colors.primary};
    text-decoration: unset;
  }
`;
export const Points = styled.div`
  margin-left: 65px;
  font-size: 14px;
  font-weight: 300;
  display: list-item;
  height: auto;
  margin-bottom: 8px;
  .teal {
    color: ${colors.primary};
  }
`;
export const Container = styled.div`
  align-items: center;
  display: flex;
  text-align: center;
  flex: 1;
  &.home {
    width: 307px;
    height: 556px;
    flex: unset;
    margin-left: 10rem;
    @media (max-width: 980px) {
      margin: auto;
      flex: 1;
      width: 280px;
      &.gogetters {
        height: 480px;
      }
    }
  }
  &.business {
    width: 510px;
    height: 373px;
    @media (max-width: 1110px) {
      margin-left: 4rem;
    }
    @media (max-width: 1015px) {
      margin-left: 1rem;
    }
    @media (max-width: 980px) {
      height: 315px;
      width: 307px;
    }
    @media (max-width: 700px) {
      height: 250px;
      width: 307px;
    }
  }
  img {
    margin: auto;
    &.home {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    width: 70%;
    @media (max-width: 480px) {
      width: 90%;
    }
    @media (min-width: 978px) {
      margin-left: unset;
      &.home {
        margin-left: auto;
      }
    }
  }
  imageDiv {
    background-image: ${props => `url(${props.image})`};
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 272px;
  }
`;
export const ComputerColumn = styled.div`
  align-items: center;
  display: flex;
  text-align: center;
  flex: 1;
  margin: auto;
  &.home {
    flex: 0;
    margin: unset;
  }
  @media (max-width: 980px) {
    display: none;
  }
`;
export const MobileColumn = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: block;
  }
`;
