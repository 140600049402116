import React from 'react';
import styled from 'styled-components/macro';
import TitleHeaderText from '../../components/TitleHeaderText';
import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const MainDiv = styled.div`
  width: 900px;
  margin: auto;
  @media (max-width: 950px) {
    width: calc(100% - 2rem);
  }
`;
const Paper = styled.div`
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
  @media (max-width: 950px) {
    justify-content: center;
    margin-top: unset;
  }
`;
const DetailsWrapper = styled.div`
  max-width: 371px;
  border-bottom: 1px solid ${colors.grey};
  @media (max-width: 950px) {
    &:last-child {
      border-bottom: none;
    }
  }
`;
const Title = styled.h3`
  font-size: 0.83rem;
  font-weight: 700;
`;
const Description = styled.p`
  font-size: ${fontSize.large};
  line-height: 19.07px;
`;

const WhatDoTheyDoStatic = props => {
  const { title, data } = props;

  return (
    <MainDiv>
      <TitleHeaderText text={title} />
      <Paper>
        {data.map((info, index) => {
          const response = JSON.parse(info.data);
          return (
            <DetailsWrapper key={index}>
              <Title>{response.title}</Title>
              <Description>{response.description}</Description>
            </DetailsWrapper>
          );
        })}
      </Paper>
    </MainDiv>
  );
};

export default WhatDoTheyDoStatic;
