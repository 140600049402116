import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';

import styled from 'styled-components/macro';

import HeaderSeo from '../components/HeaderSeo';

import Layout from '../components/Layout';

import TopHeader from '../views/shared/TopHeader';
import AdvantagesOfGoget from '../views/shared/AdvantagesOfGoget';
import HowItWorksStatic from '../views/StaticPageDatoCMS/HowItWorksStatic';
import ImageScroller from '../views/StaticPageDatoCMS/ImageScroller';
import WhatDoTheyDoStatic from '../views/StaticPageDatoCMS/WhatDoTheyDoStatic';
import MeetOurCommunityStatic from '../views/StaticPageDatoCMS/MeetOurCommunityStatic';
import FAQSection from '../views/shared/FAQ';
import ContactSection from '../components/ContactSection';

import MobileBusinessFooterBackground from '../assets/img/business/MobileBusinessFooterBackground.png';
import DesktopFooterBackground from '../assets/img/business/DesktopFooterBackground.png';

import MetaImg from '../assets/img/meta/business.png';
import ImageAndBox from '../views/StaticPageDatoCMS/ImageAndBox';
import colors from '../assets/colors';
import fontSize from '../assets/fontSize';
import { SharedBackground } from '../assets/sharedStyle';

const BackgroundFooter = styled.div`
  ${SharedBackground};
  background-image: url(${DesktopFooterBackground});
  @media (max-width: 760px) {
    background-image: url(${MobileBusinessFooterBackground});
  }
`;
const ContactUsContainer = styled.div`
  margin-top: -2rem;
`;
const HowItWorksContainer = styled.div`
  background: ${colors.blueLight};
  padding-top: 1rem;
  padding-bottom: 3rem;
`;

const LandingPage = props => {
  const [mobile, setMobile] = useState(false);
  useEffect(() => {
    const hash = window.location.hash.substr(1);
    if (hash === 'contact-us') {
      setTimeout(() => {
        const extraSize = mobile ? 350 : -10;
        const contactUs =
          document.getElementById('contact-us').offsetTop + extraSize;
        window.scroll({ top: contactUs, behavior: 'smooth' });
      }, 700);
    } else {
      window.scrollTo(0, 0);
    }
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };
  const { data } = props;
  const { datoCmsLandingPage } = data;

  const ctaButton = JSON.parse(datoCmsLandingPage?.ctaButton);
  const stats = JSON.parse(datoCmsLandingPage?.stats);

  return (
    <>
      <HeaderSeo
        title={datoCmsLandingPage?.seoTitle}
        description={datoCmsLandingPage?.seoDescription}
        image={MetaImg}
        pathname={datoCmsLandingPage?.slug}
        keywords={datoCmsLandingPage?.seoKeywords}
        schemaMarkup={datoCmsLandingPage.schemaMarkup}
      />
      <Layout>
        <TopHeader
          backgroundImg={datoCmsLandingPage?.desktopBanner?.fluid?.src}
          mobileBackgroundImg={datoCmsLandingPage?.mobileBanner?.fluid?.src}
          title={datoCmsLandingPage?.title}
          description={datoCmsLandingPage?.description}
          btn1={{ text: ctaButton?.text, link: ctaButton?.link }}
          h4={datoCmsLandingPage?.category}
          backgroundMobileHeight="800px"
          secondary
        />
        <AdvantagesOfGoget data={datoCmsLandingPage?.usp} />
        <ImageAndBox
          data={datoCmsLandingPage?.payPerUseSection}
          jobType={datoCmsLandingPage?.title}
        />
        <HowItWorksContainer>
          <HowItWorksStatic />
        </HowItWorksContainer>
        <br />
        <MeetOurCommunityStatic data={datoCmsLandingPage?.gogetters} />
        <ImageScroller
          desktopImages={datoCmsLandingPage?.imageGalleryDesktop}
          mobileImages={datoCmsLandingPage?.imageGalleryMobile}
          jobType={datoCmsLandingPage?.title}
          stats={stats}
        />
        <WhatDoTheyDoStatic
          title={`What does a ${datoCmsLandingPage?.title} usually do?`}
          data={datoCmsLandingPage?.whatDoTheyDo}
        />
        <BackgroundFooter>
          <FAQSection
            styleProps={'margin-bottom: 7rem'}
            data={datoCmsLandingPage?.faqs}
            isDataFromDatoCMS
          />
          {datoCmsLandingPage?.isContactUsFormLoaded && (
            <ContactUsContainer id="contact-us">
              <ContactSection
                hideBackground
                title={
                  <h2 style={{ fontSize: fontSize.twoRem }}>
                    {' '}
                    Let us help grow your business!{' '}
                  </h2>
                }
                subtext="Provide us with your details and we will get in touch."
              />
            </ContactUsContainer>
          )}
        </BackgroundFooter>
      </Layout>
    </>
  );
};

export const query = graphql`
  query LandingPageQuery($pageIdJim: String) {
    datoCmsLandingPage(originalId: { eq: $pageIdJim }) {
      originalId
      seoTitle
      seoDescription
      seoKeywords
      slug
      title
      description
      category
      schemaMarkup
      mobileBanner {
        fluid(maxWidth: 600) {
          ...GatsbyDatoCmsSizes
        }
      }
      desktopBanner {
        fluid(maxWidth: 600) {
          ...GatsbyDatoCmsSizes
        }
      }
      ctaButton
      stats
      isContactUsFormLoaded
      faqs {
        id
        data
      }
      whatDoTheyDo {
        data
        id
      }
      payPerUseSection {
        serviceFee
        serviceImage {
          fluid(maxWidth: 600) {
            ...GatsbyDatoCmsSizes
          }
        }
      }
      imageGalleryDesktop {
        fluid(maxWidth: 600) {
          ...GatsbyDatoCmsSizes
        }
      }
      imageGalleryMobile {
        fluid(maxWidth: 600) {
          ...GatsbyDatoCmsSizes
        }
      }
      usp {
        uspTitle
        uspDescription
        uspIcon {
          fluid(maxWidth: 600) {
            ...GatsbyDatoCmsSizes
          }
        }
      }
      gogetters {
        name
        photo {
          fluid(maxWidth: 600) {
            ...GatsbyDatoCmsSizes
          }
        }
        jobCompleted
        rating
        since
        experience {
          id
          name
          link
          image {
            fluid(maxWidth: 600) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
      }
    }
  }
`;

export default LandingPage;
