import React from 'react';

import styled from 'styled-components/macro';

import { Star } from '@material-ui/icons';

import colors from '../assets/colors';
import fontSize from '../assets/fontSize';

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StarIcon = styled(Star)`
  color: ${colors.gold} !important;
  font-size: 18px !important;
  margin-right: 4px;
`;
const RatingNo = styled.p`
  color: ${colors.gold};
  font-size: ${fontSize.regular};
  margin-left: 8px;
`;

const Stars = props => {
  const { rate, hideRate } = props;
  const renderIcon = () => {
    var rows = [];
    for (var i = 0; i < 5; i++) {
      rows.push(<StarIcon key={i} />);
    }
    return rows;
  };

  return (
    <IconWrapper>
      {renderIcon()}
      {!hideRate && <RatingNo>{rate || '5.0'}</RatingNo>}
    </IconWrapper>
  );
};

export default Stars;
